<template>
  <div :class = "areadyRegister===false ? 'mblogin-wrap' : 'mblogin-wrap positionPage'">
    <div class="register-wrap" v-if="!areadyRegister">
      <img v-if="!nextEditorTag" src="../../../public/static/img/mblogo.png" class="logo-img"/>
      <div class="register-form">
        <div v-if="!nextEditorTag">
          <img :src="userInfor.headimgurl" v-if="userInfor.headimgurl && userInfor.headimgurl !== ''"/>
          <img src="../../../public/static/img/hasnothing.png" v-else/>
          <p class="register-nickname">{{ userInfor.nickname }}</p>
        </div>
        <el-form :model="userInfor" :rules="userRuleForm" ref="userInfor" size="" style="padding: 0 10px;" v-if="!nextEditorTag">
          <el-form-item label="国际区号">
            <el-input v-model="userInfor.country_code" placeholder="86"></el-input>
          </el-form-item>
          <el-form-item prop="phone">
            <el-input v-model="userInfor.phone" placeholder="手机号码（必填）"></el-input>
          </el-form-item>
          <el-form-item prop="verification" class="verification-wrap">
            <el-col :span="14"><el-input v-model="userInfor.verification" placeholder="手机验证码（必填）"></el-input></el-col>
            <el-col :span="10" style="text-align: right;"><el-button :disabled="codeButton.isDisabled" @click="getPhoneCodeFunc">{{ codeButton.name }}</el-button></el-col>
          </el-form-item>
<!--          <el-form-item v-if="validCodeControl" porp="invite_code">-->
<!--            <el-input v-if="!ifNeedVisiteCode" v-model="userInfor.invite_code" disabled></el-input>-->
<!--            <el-input v-else v-model="userInfor.invite_code" placeholder="邀请码（必填）"></el-input>-->
<!--          </el-form-item>-->
          <el-col :span="24" class="registdialog-footer">
            <el-button type="primary" data-tag-id="mobile-user-login" @click="registNextFunc" class="btn-all">立即加入</el-button>
          </el-col>
        </el-form>
      </div>
    </div>
  </div>
<!--  <p>-->
<!--    <span>{{redirect_url}}</span> <el-button @click="manualRedirect">手动跳转</el-button>-->
<!--  </p>-->
</template>

<script>
import { getPhoneCode, checkPhoneCode, getValid, checkValidCode,requestLogin } from '../../api/api'
import { getCookie } from "../../util";
import config from "../../config";
import axios from "axios";
import {ElMessage} from "element-plus";

export default {
  name: 'MobileLogin',
  data () {
    return {
      areadyRegister: false,
      isLoginRequestNow: false,
      userInfor: {
        interest: ['并购'],
        nickname: '游客',
        headimgurl: '',
        invite_code: config.registerInviteCode,
        country_code: '86'
      },
      checkCodeOk: false,
      checkVisiteCodeOk: false,
      userRuleForm: {
        phone: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { min: 8, max: 14, message: '请输入8-14位的手机号码', trigger: 'blur' }
        ],
        verification: [
          { max: 4, message: '请输入4位手机验证码', trigger: 'change' },
          { required: true, message: '请输入手机验证码', trigger: 'blur' }
        ],
      },
      checkboxTagsGroup: ['并购', '资本市场', '境外投资', '私募企业', '房地产', '知识产权', '税法', '劳动', '争议解决', '银行与金融', '反垄断', '破产', '海事海商', '国际贸易', '公司综合', '传媒/娱乐/体育', 'WTO', '资产证券化', '保险', 'TMT', '医疗', '刑事', '融资租赁', '婚继家事', '矿业/资源/环境'],
      codeButton: {
        isDisabled: false,
        name: '发送验证码',
        time: 60
      },
      validCodeControl: true,
      nextEditorTag: false,
      locationUrl: 'https://' + config.domain,
      ifNeedVisiteCode: false,
      user: null,
      team: null,
      redirect_url:localStorage.getItem('redirect_url'),
      // videoNotView: true,
    }
  },
  created () {
    let uid = getCookie('uid');
    let register = getCookie('register');
    // ElMessage.info({message:`register is ${register}`, duration: 0,showClose:true});
    if (register == '1') {

      document.cookie = 'isRegister' + '=' + 'true';
      this.areadyRegister = false
      this.userInfor.nickname = decodeURIComponent(getCookie('nickname'));
      if (getCookie('headimgurl').indexOf('"') !== -1) {
        this.userInfor.headimgurl = getCookie('headimgurl').substring(1, getCookie('headimgurl').length - 1)
      } else {
        this.userInfor.headimgurl = getCookie('headimgurl')
      }
      let params = {
        type: 'invite_code',
        content: 'SYS-OPN00000000'
      }
      getValid(params).then(res => {
        if (res.data.code === 0) {
          this.userInfor.invite_code = config.registerInviteCode;
          this.validCodeControl = true
        } else {
          this.validCodeControl = false
        }
      })
    } else {
      this.areadyRegister = true

      this.checkGoRedirectUrl();
    }
  },
  computed: {
    userChooseTags () {
      return this.userInfor.interest
    },
  },
  methods: {
    // getUserInfoFunc: function () {
    //   getMyInfo({}).then(res => {
    //     if (res.data.code === 0) {
    //       this.user = res.data.data
    //       if (res.data.data.team_configs && res.data.data.team_configs.team_id !== null) {
    //         if (res.data.data.team_configs.is_manager) {
    //           this.$message({
    //             type: 'warning',
    //             message: '您是团队管理员，不能更换团队'
    //           })
    //         } else {
    //           if (res.data.data.team_configs.team_id !== this.team.team_id) {
    //             this.$confirm('您已有团队，是否退出当前团队加入新团队?', '提示', {
    //               confirmButtonText: '确定',
    //               cancelButtonText: '取消',
    //               type: 'warning'
    //             }).then(() => {
    //               let params = {
    //                 team_id: this.team.team_id,
    //                 member_uid: this.user.uid
    //               }
    //               deleteTeamMember(params).then(res => {
    //                 if (res.data.code === 0) {
    //                   this.addTeamFunc()
    //                 } else {
    //                   this.$message({
    //                     type: 'error',
    //                     message: res.data.msg
    //                   })
    //                 }
    //               }).catch(res => {
    //                 this.$message({
    //                   type: 'error',
    //                   message: '退出当前团队失败，请检查网络'
    //                 })
    //               })
    //             }).catch(() => {
    //               this.$message({
    //                 type: 'info',
    //                 message: '已取消'
    //               })
    //             })
    //           } else {
    //             this.$message({
    //               type: 'warning',
    //               message: '您已经是该团队成员'
    //             })
    //           }
    //         }
    //       } else {
    //         this.addTeamFunc()
    //       }
    //     } else {
    //       console.log(res.data.msg + '获取用户个人信息失败')
    //     }
    //   }).catch(res => {
    //     console.log('获取用户信息失败 res ===', res)
    //   })
    // },
    // getTeamInfor: function () {
    //   getTeamByParams({invite_code: this.$route.query.vc}).then(res => {
    //     if (res.data.code === 0) {
    //       this.team = res.data.data.items[0]
    //       this.getUserInfoFunc()
    //     }
    //   })
    // },
    // addTeamFunc: function () {
    //   let roots = {
    //     member_uid: this.user.uid
    //   }
    //   let params = {
    //     invite_code: this.$route.query.vc
    //   }
    //   addTeam(roots, params).then(res => {
    //     if (res.data.code === 0) {
    //       this.$message({
    //         type: 'success',
    //         message: '加入团队成功'
    //       })
    //     } else {
    //       this.$message({
    //         type: 'error',
    //         message: res.data.msg
    //       })
    //     }
    //   }).catch(res => {
    //     this.$message({
    //       type: 'error',
    //       message: '加入团队失败'
    //     })
    //   })
    // },
    manualRedirect(){
      window.location.href = this.redirect_url;
    },
    registNextFunc: function () {
      this.$refs['userInfor'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.phoneCodeCheckFunc()
      })
    },
    registSubmit: function () {
      let params = Object.assign({}, this.userInfor);
      const source = this.$route.query.s;
      requestLogin(source,params,res => {
        if (res.user && res.user.code === 0) {
          this.areadyRegister = true
          this.checkGoRedirectUrl();
        } else {
          this.$message({
            type: 'error',
            message: res.data.msg
          })
        }
      })
    },
    getPhoneCodeFunc: function () {
      this.$refs['userInfor'].validateField('phone', (valid) => {
        if (valid) {
          return false
        }
        let type = {
          type: 'signup'
        }
        let params = {
          phone: this.userInfor.country_code === '' || this.userInfor.country_code === undefined ? '86-' + this.userInfor.phone : this.userInfor.country_code + '-' + this.userInfor.phone
        }
        getPhoneCode(type, params).then(res => {
          if (res.data.code === 0) {
            let _this = this
            _this.codeButton.isDisabled = true
            let timer = window.setInterval(function () {
              _this.codeButton.name = '(' + _this.codeButton.time + '秒)后重发'
              --_this.codeButton.time
              if (_this.codeButton.time < 0) {
                _this.codeButton.time = 60
                _this.codeButton.isDisabled = false
                _this.codeButton.name = '发送验证码'
                window.clearInterval(timer)
              }
            }, 1000)
          } else {
            this.$alert(res.data.msg)
          }
        }).catch(res => {
          this.$message({
            type: 'error',
            message: '发送手机验证码失败，请稍后再试'
          })
        })
      })
    },
    phoneCodeCheckFunc: function () {
      if (!this.checkCodeOk) {
        let params = {
          type: 'signup',
          content: this.userInfor.verification
        }
        checkPhoneCode(params).then(res => {
          if (res.data.code === 0) {
            this.checkCodeOk = true
            this.userInfor.invite_code = config.registerInviteCode;
            this.checkVisiteCodeOk = true;
            this.ifNeedVisiteCode = false;
            this.registSubmit();
          } else {
            this.checkCodeOk = false
            this.$message({
              type: 'error',
              message: '输入的验证码不正确 res:'+res
            })
          }
        }).catch(res => {
          this.checkCodeOk = false
          this.$message({
            type: 'error',
            message: '验证码验证失败，请重新验证,res:'+res
          })
        })
      } else {
        this.checkVisiteCodeFunc()
      }
    },
    checkVisiteCodeFunc: function () {
      let params = {
        type: 'invite_code',
        content: this.userInfor.invite_code,
      }
      checkValidCode(params).then(res => {
        if (res.data.code === 0) {
          this.checkVisiteCodeOk = true
          this.ifNeedVisiteCode = false
          this.registSubmit()
          // this.nextEditorTag = true
        } else {
          this.checkVisiteCodeOk = false
          this.ifNeedVisiteCode = true
          this.$message({
            type: 'warning',
            message: `邀请码不正确或者无效 邀请码: ${params.content}  res:`+JSON.stringify(res)
          })
        }
      }).catch(res => {
        this.checkVisiteCodeOk = false
        this.$message({
          type: 'error',
          message: `邀请码验证失败，请重新验证  邀请码: ${params.content} res:`+JSON.stringify(res)
        })
      })
    },
    checkGoRedirectUrl:function () {
      //这是一个后端接口
      // 2022-5-7 这也会是个前端页面如(viewVersionFile)
      const redirectUrl = this.redirect_url;
      // ElMessage.info({message:`跳转url : ${redirectUrl}`, duration: 0});

      if(redirectUrl){
        window.location.href = this.redirect_url;
      }else if(this.$route.query.redirect){
        this.$router.push(this.$route.query.redirect);
      }else{
        this.$router.push('/index');
      }
    }
  },
  watch: {
    userChooseTags: function (val, oldal) {
      if (val.length > 3) {
        this.userInfor.interest = val.slice(0,3)
        this.$notify.info({
          title: '提示',
          message: '最多选择三个标签领域',
          type: 'warning'
        })
      }
    },
  }
}
</script>

<style lang="scss">
.mblogin-wrap {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background: #f9f9f9;
  background: -moz-linear-gradient(top,  #f9f9f9 0%, #e4e4e3 100%);
  background: -webkit-linear-gradient(top,  #f9f9f9 0%,#e4e4e3 100%);
  background: -o-linear-gradient(top,  #f9f9f9 0%,#e4e4e3 100%);
  background: -ms-linear-gradient(top,  #f9f9f9 0%,#e4e4e3 100%);
  background: linear-gradient(to bottom,  #f9f9f9 0%,#e4e4e3 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f9f9f9', endColorstr='#e4e4e3',GradientType=0 );
  position: relative;
  .register-wrap {
    padding-bottom: 3rem;
    padding-top: 1rem;
    .logo-img {
      width: 100px;
      height: auto;
      margin: 6rem auto;
    }
    .register-form {
      position: relative;
      width: 90%;
      background-color: rgba(255, 255, 255, 0.7);
      height: 100%;
      margin: 0 auto;
      border-radius: 4px;
      img {
        position: absolute;
        top: -30px;
        left: 50%;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin-left: -30px;
      }
      .register-nickname {
        padding-top: 40px;
        padding-bottom: 15px;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .el-form {
        overflow: hidden;
      }
      .el-input.is-disabled .el-input__inner {
        color: #000!important;
        border-color: #a9aaab!important;
      }
      .regist-tags-wrap {
        .el-form-item__label {
          width: 100%!important;
        }
        .el-form-item__content {
          margin-left: 0!important;
          line-height: 30px;
          text-align: left;
        }
        .el-checkbox-button {
          margin-right: 14px;
          margin-bottom: 10px;
        }
        span {
          color: #606266;
          border: 1px solid #606266!important;
          border-radius: 4px!important;
          padding: 8px 15px;
          font-size: 12px;
          font-weight: normal;
        }
        .is-checked span {
          color: #FFFFFF;
          background-color: #3759CB;
        }
      }
      .registdialog-footer {
        margin-bottom: 30px;
        p {
          font-size: 14px;
          margin-bottom: 10px;
          span {
            color: #3759CB;
            margin-left: 5px;
          }
        }
      }
    }
  }

}
.positionPage {
  position: absolute;
  top: 0;
  bottom: 0;
}
@media screen and (max-width: 768px) {
  .el-message-box {
    width: 90%;
  }
}
@media screen and (max-width: 320px) {
  .mblogin-wrap {
    height: 100%;
  }
  .mblogin-wrap .register-wrap .logo-img {
    margin: 1.5rem auto 3rem;
  }
  .verification-wrap .el-col-14 {
    width: 45%;
  }
  .verification-wrap .el-col-10 {
    width: 54%;
  }
  .logo-img {
    margin: 3rem auto!important;
  }
}
</style>
